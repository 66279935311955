.table {
  margin: 8px -24px 0;
}
.table_container {
  width: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
  width: 100%;
  display: flex;

  & > * {
    transform: rotateX(180deg);
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.shadowed {
  & .sticky {
    box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.1);
  }
}

.table_row {
  white-space: nowrap;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  & > * {
    height: 40px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    white-space: nowrap;
  }

  &.hovered {
    background-color: var(--neutral-100) !important;
  }

  &.active {
    background-color: var(--neutral-200) !important;
  }

  &_base > :first-child {
    width: 158px;
  }
  &_base > :nth-child(2) {
    width: 140px;
  }
  &_base > :nth-child(3) {
    width: 140px;
  }
  &_base > :nth-child(4) {
    width: 158px;
  }

  .table_row_status {
    max-width: 200px;
  }
  .table_row_status > div {
    overflow: hidden;
    width: 100%;
  }
  .table_row_status > div > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table_row_chevron {
    width: 52px;
  }
  .table_row_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > svg {
      position: absolute;
    }
  }
}

.filters {
  padding: 6px 0;
}
.filters_options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.loading_more {
  background: white !important;

  & td > div {
    padding: 8px;
  }
}
