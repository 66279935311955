.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .field_wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  & .field {
    width: 500px;
    position: relative;

    & > svg {
      position: absolute;
      pointer-events: none;
      top: calc(50% + 11px);
      transform: translateY(-50%);
      right: 12px;
    }
  }
}
