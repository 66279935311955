.preview-content {
  --aside-width: 313px;

  display: flex;
  justify-content: space-between;
  gap: 24px;

  &__details {
    width: var(--aside-width);
    display: flex;
    flex-direction: column;
    gap: 38px;

    &__box {
      border-radius: 20px 20px 0 0;
      background: var(--main-neutral);
      overflow: hidden;

      &--title {
        color: var(--main-white);
        padding: 16px 20px 12px;
      }

      &--content {
        border-radius: 16px 16px 0 0;
        background: var(--main-white);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > div {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }
    }
  }

  &__questions {
    padding: 16px 0;
    width: calc(100% - var(--aside-width));

    & > div {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
