.card {
  border-radius: 16px;
  background-color: #e6f2fd;
  padding: 16px;
}

.collapsed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  .title {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .toggler {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.content {
  padding-top: 16px;
}
