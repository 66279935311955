html {
  font-family: "SF Pro Text", sans-serif;
  box-sizing: border-box;
  min-height: 100vh;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-content);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
}

:global(.react-datepicker__day--outside-month) {
  visibility: hidden;
}

* {
  -webkit-text-fill-color: currentColor !important;
}

textarea:disabled {
  background-color: transparent;
  color: currentColor;
}
