.table {
  margin: 8px -24px 0;
}

.table_container {
  width: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
  width: 100%;
  display: flex;

  & > * {
    transform: rotateX(180deg);
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.shadowed {
  & .sticky {
    box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.1);
  }
}

.table_body > tr {
  background: white;

  & > * {
    height: 40px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    white-space: nowrap;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 17, 51, 0.12);
  }
}

.table_head_row {
  white-space: nowrap;
}

.table_row {
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &.hovered {
    background-color: var(--neutral-100) !important;
  }

  &.active {
    background-color: var(--neutral-200) !important;
  }
}

.filters_radio {
  display: flex;
  align-items: center;
  gap: 16px;
}
