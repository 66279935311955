.base {
  position: fixed;
  z-index: 9999;
  inset: 0px;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop {
  position: fixed;
  z-index: 1;
  background: var(--background-overlay);
  inset: 0;
}

.wrapper {
  background-color: white;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 32px;
  position: relative;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
  box-shadow: rgba(0, 17, 51, 0.08) 0px 3px 8px 0px, rgba(0, 17, 51, 0.16) 0px 18px 28px 0px;
  border-radius: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 24px 8px;
  gap: 8px;
  position: relative;

  &.variant--bordered {
    border-bottom: 1px solid #d6d9de;
  }

  .icon_handler {
    display: flex;
    align-items: center;
    justify-content: center;

    &.backgrounded {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &.variant--common {
    & .icon_handler + div > h2 {
      min-height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .header_box {
    & > div {
      gap: 16px;
    }
  }

  &.variant--info-iconed {
    & .header_box {
      width: 100%;

      & > div {
        flex-direction: column;
        align-items: center;
      }

      & h2,
      & .subtitle {
        text-align: center;
      }
    }

    & .header_actions {
      position: absolute;
      right: 24px;
      top: 24px;
    }
  }

  .subtitle {
    margin-top: 4px;
    white-space: pre-wrap;
  }

  .header_actions {
    display: flex;
    min-height: 24px;
    align-items: center;
    gap: 12px;
  }

  .close_btn {
    min-width: 24px;
    cursor: pointer;
  }
}

.content {
  padding: 0 24px;
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    width: 14px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 14px;
    background: #d6d9de;
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  gap: 8px;

  &.bordered {
    border-top: 1px solid #d6d9de;
  }
}

.enter {
  & .backdrop {
    opacity: 0;
  }
  & .wrapper {
    opacity: 0;
    transform: scale(0.4);
  }
}

.enterActive {
  & .backdrop {
    transition: opacity 250ms;
    opacity: 1;
  }
  & .wrapper {
    transition: opacity 250ms, transform 250ms;
    opacity: 1;
    transform: scale(1);
  }
}

.exit {
  & .backdrop {
    opacity: 1;
  }
  & .wrapper {
    opacity: 1;
    transform: scale(1);
  }
}

.exitActive {
  & .backdrop {
    transition: opacity 250ms;
    opacity: 0;
  }
  & .wrapper {
    transition: opacity 250ms, transform 250ms;
    opacity: 0;
    transform: scale(0.4);
  }
}
