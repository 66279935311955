@import "../../../../../assets/styles/colors.scss";

.section {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, calc(50% - 10px));
  justify-content: space-between;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
}
.common_error {
  margin-top: 8px;
}

.owners {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.owners_add_button {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.owner_modal {
  position: fixed;
  width: 800px;
  border-radius: 16px;
  background: $main-white;
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  z-index: 1;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
}

.owner_form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > div {
    flex-basis: calc(50% - 10px);

    &.full_field {
      flex-basis: 100%;
    }
  }
}

.owner_item {
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  & > .owner_name {
    width: calc(100% - 96px);

    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > .owner_actions {
    display: flex;
    align-items: center;
    gap: 4px;

    & .owner_btn {
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 50%;
      background: $controls-secondary-background;
    }

    & .open_button {
      cursor: pointer;
      user-select: none;
    }
  }
}
