@import "../../../assets/styles/colors.scss";

.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: $main-white;
  padding: 8px 12px;
  gap: 8px;

  & > .question__title {
    width: calc(100% - 72px);
    text-overflow: ellipsis;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 40px;
    overflow: hidden;

    &.question__title__multiline {
      background: linear-gradient(180deg, #2e2d2d 0%, rgba(46, 45, 45, 0.2) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  & > .question_actions {
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 0;

    & .button {
      width: 16px;
      height: 16px;
    }
  }
  &:hover > .question_actions {
    opacity: 1;
  }
}
