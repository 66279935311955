.b-snt-passport-block-content-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > div {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);

    &[class^="full-field"],
    &[class*=" full-field"] {
      flex-basis: 100%;
      max-width: 100%;
    }

    &[class^="col-3-field"],
    &[class*=" col-3-field"] {
      flex-basis: calc(33.33% - 40px / 3);
      max-width: calc(33.33% - 40px / 3);
    }
  }
}
