.header {
  padding-bottom: 24px;

  & > div {
    min-height: 44px;
  }
}

.create_dir_btn {
  border-radius: 20px;
  background: var(--main-white);
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 44px 40px;
  border: 2px dashed var(--background-border);
  box-sizing: border-box;
  transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: var(--main-white);
  }
}

.edit_dir_footer {
  justify-content: space-between;
}

.directory_wrapper {
  padding: 16px 0;
}

.directories_list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.directory {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;

  & .title_container {
    display: flex;
    align-items: center;
    gap: 12px;
    max-width: calc(100% - 160px);

    & .title {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  & [role="button"] {
    cursor: pointer;
  }

  .add_document {
    padding: 0 28px;
  }
}

.files_list {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.no_directories {
  padding: 24px 0;
}

.fixed_creat_btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -120px;
  right: -120px;
  width: 500px;
  height: 500px;
  cursor: pointer;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    z-index: 1;
    position: relative;
  }

  & > span {
    background: linear-gradient(#fff 0 0) padding-box, linear-gradient(to bottom, #226dff, #de0bb0) border-box;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    border-radius: 50%;
    animation-duration: 1200ms;
    animation-name: scaleAnimation;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    border: 2px dashed #fff;
    overflow: hidden;

    & > span {
      background: #ecf4ff;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  @keyframes scaleAnimation {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
  }
}
