.b-select-addresses-submodal {
  position: fixed;
  width: 545px;
  border-radius: 16px;
  background: var(--main-white);
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  &__addresses {
    height: 288px;
    padding: 0 24px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 14px;
      height: 14px;
      position: relative;
    }
    &::-webkit-scrollbar-track {
      width: 14px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 14px;
      background: #d6d9de;
      border: 3px solid transparent;
      background-clip: padding-box;
      border-radius: 10px;
    }
  }

  &__search {
    padding: 12px 24px;

    &_field {
      position: relative;

      & > svg {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }

      & > input {
        width: 100%;
        height: 40px;
        padding: 0 12px 0 32px;
        border-radius: 10px;
        border: 1px solid #ebecf1;
        transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        outline: none;
        background-color: #ebecf1;

        &::-webkit-input-placeholder {
          color: var(--text-secondary);
          opacity: 1;
        }

        &:hover {
          border-color: rgba(43, 57, 73, 0.32);
        }

        &:focus-visible {
          border-color: #226dff;
        }
      }
    }
  }
}
