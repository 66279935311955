.question_item {
  border-radius: 16px;
  background: #edeef2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 27px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background: #dcdde8;
  }

  & > .question_item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    & > h4 {
      white-space: nowrap;
    }

    & > div {
      gap: 12px;
      text-align: right;
    }
  }

  & > .question_item__files {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
      gap: 4px;
    }
  }

  & > .question_item__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.selectable {
    cursor: pointer;
    user-select: none;
  }

  & > .question_item__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 40px;
    overflow: hidden;

    &.question_item__content_multiline {
      background: linear-gradient(180deg, #2e2d2d 0%, rgba(46, 45, 45, 0) 150%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
