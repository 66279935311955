.created-title {
  background: linear-gradient(92deg, #0bb261 0%, #63c517 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
}

.finish-step {
  position: relative;
  margin: 0 -24px;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 278px;
    height: 278px;
    top: 78px;
    left: -121px;
    border-radius: 100%;
    background: #b9ffb2;
    filter: blur(100px);
    z-index: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding: 0 24px;
    z-index: 1;
    position: relative;

    & > div > h3 {
      margin-bottom: 16px;
    }

    &--lokoloside {
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    &--authorside {
      display: flex;
      flex-direction: column;
      gap: 16px;

      & > div {
        display: flex;
        gap: 12px;

        & > span {
          border-radius: 24px;
          background: #0db360;
          width: 40px;
          min-width: 40px;
          height: 40px;
          color: var(--main-white);
        }

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
