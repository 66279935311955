.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
}

.accordion_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.accordion_content {
  & > h5 {
    text-transform: uppercase;
  }

  & > div {
    display: flex;
    gap: 16px;
    margin-top: 8px;
  }
}

.loader {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;
  height: 8px;
  background-color: var(--neutral-200);
}
