.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .collapsed {
    padding: 16px 20px;
    border-radius: 16px;
    background: #e6e9ee;
    display: flex;
    align-items: center;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background: #dadee4;
    }

    & .icon {
      display: flex;
      align-items: center;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    & .actions {
      margin-left: auto;
    }
  }

  & .expanded {
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
  }
}
