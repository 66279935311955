.wrapper {
  padding-bottom: 68px;
}

.owner {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .files {
    margin-top: 16px;

    & > *:not(:first-child) {
      margin-top: 12px;
    }
  }

  .owner_details {
    display: table;

    & > div {
      display: table-row;

      &:not(:first-child) > span {
        padding-top: 12px;
      }

      & > span {
        display: table-cell;

        &:first-child {
          padding-right: 12px;
          width: 196px;
        }
      }
    }
  }
}

.questions {
  margin-top: 20px;

  & .questions_header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .questions_header_selects {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  & .questions_list {
    display: flex;
    padding: 40px 0;
    flex-direction: column;
    gap: 32px;
  }
}

.question_decision {
  display: flex;
  gap: 16px;

  & .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 24px;
    background: var(--neutral-200);
  }

  & .question_decision_content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .question_decision_text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    & .question_decision_buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }
}

.radio {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  & .input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;

    & input {
      cursor: inherit;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      margin: 0px;
      padding: 0px;
      z-index: 1;
    }
  }

  & svg > rect {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover svg > rect {
    opacity: 1;
  }
}

.bottom_bar {
  position: fixed;
  right: 0;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  width: calc(100% - var(--aside-width));
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  background: var(--main-white);
  border-top: 1px solid var(--background-border);
}

.success_modal_actions {
  width: 100%;

  & > button {
    width: 100%;
  }
}
