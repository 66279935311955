.b-map-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 350px;
  margin: 0 -24px -24px;
  position: relative;
}
.b-map-toolbar-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.b-selected-marker {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  width: 300px;
  height: 100%;
  background-color: var(--main-white);
  padding: 24px;

  &__header {
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    height: calc(100% - 32px);

    & .is-loading {
      pointer-events: none;
      opacity: 0.5;
    }

    &_card {
      border-radius: var(--big-card-border-radius);
      background-color: #ebf5ff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin: 16px 0;

      & > div {
        width: 100%;
      }

      & > div,
      & > span {
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    &_details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }
}

.enter {
  transform: translateX(-100%);
}

.enterActive {
  transition: transform 250ms ease-in-out;
  transform: translateX(0);
}

.exit {
  transform: translateX(0);
}

.exitActive {
  transition: transform 250ms ease-in-out;
  transform: translateX(-100%);
}
