.search_query {
  position: relative;

  & .icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}
