.container {
  position: relative;

  & > div {
    display: inline-flex;
  }

  & > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: 1;
  }
}
