@import "../../../../../../assets/styles/colors.scss";

.meeting {
  display: flex;
  gap: 16px;

  & > div {
    border-radius: 12px;
    background: $neutral-200;
    padding: 12px;
    min-height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;

    & > div {
      display: flex;
      align-items: center;
      gap: 4px;

      & .accent_value {
        font-size: 40px;
        line-height: 44px;
        letter-spacing: 0.4px;

        &.completed {
          color: #25c168;
        }
        &.not_completed {
          color: #ed0a34;
        }
      }
    }
  }
}

.counter {
  border-radius: 8px;
  padding: 4px 8px;
  color: $main-white;

  &.completed {
    background: #25c168;
  }
  &.not_completed {
    background: #ed0a34;
  }
}
