.b-icon-btn {
  width: var(--base-field-height);
  height: var(--base-field-height) !important;
  padding: 0 !important;

  &:hover {
    background-color: var(--controls-secondary-controls-hover);
  }
  &:active {
    background-color: var(--controls-neutral-controls);
    & svg path {
      fill: var(--icons-white);
    }
  }
}
