.section {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, calc(50% - 10px));
  justify-content: space-between;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
}
.common_error {
  margin-top: 8px;
}

.owners {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.owners_add_button {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.owner_modal {
  position: fixed;
  width: 800px;
  border-radius: 16px;
  background: var(--main-white);
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  z-index: 1;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
}

.owner_form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > div {
    flex-basis: calc(50% - 10px);

    &.full_field {
      flex-basis: 100%;
    }
  }
}
