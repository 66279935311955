.card {
  border-radius: 12px;
  background: var(--main-white);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

.list > .group:not(:last-child) {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px dashed rgba(0, 17, 51, 0.24);
}

.group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;

  & .group_title {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > div {
      padding-bottom: 4px;
    }
  }
}
