.b-chart {
  display: flex;
  align-items: center;
  gap: 16px;

  &__donut {
    width: 100px;
    height: 100px;
    position: relative;

    & > h4 {
      position: absolute;
      width: 100%;
      padding: 0 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_item {
      min-height: 28px;
      display: flex;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        position: relative;

        &:hover > .b-chart__list_item__backdrop {
          opacity: 1;
        }
      }

      &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        z-index: 1;
        opacity: 0;
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &__content {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 8px;
        position: relative;
        cursor: pointer;
        z-index: 2;

        & > span {
          border-radius: 6px;
          color: var(--main-white);
          padding: 2px 4px;
        }
      }

      &--active &__backdrop {
        opacity: 1;
      }
    }
  }
}
