.b-analytics-filter {
  padding: 16px 24px;
  background: var(--main-white);
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__base {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1;
  }

  &__field {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
