.b-accordion-filters {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-item {
    display: table-row;

    & > div {
      display: table-cell;
      vertical-align: middle;
      height: 48px;
    }

    &__head {
      padding-right: 12px;
    }

    &__body {
      min-width: 300px;
    }
  }
}
