.p-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: var(--main-primary);
  padding: 24px;
  overflow-y: auto;
}

.p-auth__wrapper {
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  justify-content: space-between;
}

.p-auth__header {
  padding-top: 96px;
  color: var(--main-white);
}

.p-auth__card {
  background: var(--main-white);
  padding: 40px 64px;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 444px;

  &_logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
  }
}

.p-auth__tabs {
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: rgba(43, 57, 73, 0.08);
  gap: 4px;
  overflow: hidden;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 2px);
    height: 44px;
    cursor: pointer;
    user-select: none;
    color: rgba(43, 57, 73, 1);
    border-radius: 12px;

    &.tab--active {
      background-color: rgba(43, 57, 73, 1);
      color: var(--main-white);
    }
  }
}

.p-auth__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.p-auth__form_submit {
  width: 100%;
  height: 48px !important;
  font-size: 14px !important;
}

.p-auth__footer {
  color: var(--main-white);
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 42px 0 24px;

  & a {
    color: var(--main-white);
    text-decoration: none;
  }
}

.p-auth__back {
  cursor: pointer;
  position: absolute;
  top: 14px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.subBackground {
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 0;

  & > svg {
    position: relative;
    bottom: -3px;
  }
}
