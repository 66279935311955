@import "../../assets/styles/colors.scss";
@import "../../assets/styles/common.scss";

.container::after {
  content: attr(data-error);
  display: block;
  color: $red;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.154px;
  max-height: 0;
  margin-top: 0;
  transition: all 0.2s ease;
  overflow: hidden;
  @include sf-text-regular;
}
.has_error::after {
  margin-top: 4px;
  max-height: 54px;
}
.locked input {
  padding-right: 40px !important;
}

.field {
  position: relative;

  & > .endAdornment {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 16px;
    height: 16px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
}

.counter {
  color: $text-olor-text-body-secondary;
  margin-bottom: 4px;

  & .counter_error {
    color: $error-text;
  }
  & .counter_positive {
    color: $main-primary;
  }
}

.datepicker_icon {
  min-width: 16px;
}

.datepicker_today {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  border-radius: 8px;
  background: #fbc5ef;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}

.datepicker_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px 8px 0px;
  gap: 4px;

  & > .datepicker_month_buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    & > span {
      cursor: pointer;
      user-select: none;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.datepicker_dropdown_options {
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 4px;
  width: max-content;
}

.datepicker_months_options {
  overflow: hidden;

  & .datepicker_months_options_scroll {
    max-height: 230px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.datepicker_dropdown_item {
  padding: 1px 4px;
  display: flex;
  border-radius: 4px;
  padding: 10px 12px;
  align-items: center;
  width: 100%;

  &.selected {
    background: #dce8fe;
  }
}

.datepicker_dropdown_year_button {
  border-radius: 4px;
  background: #dce8fe;
  padding: 12px;
  cursor: pointer;
  user-select: none;
}

.close {
  cursor: pointer;
  user-select: none;
}
