.root {
  width: 100%;
  position: relative;

  &.disabled:not(.variant-formfield) .display {
    opacity: 0.5;
    pointer-events: none;
  }

  &::after {
    content: attr(data-error);
    display: block;
    color: var(--text-error);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    max-height: 0;
    margin-top: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  &.has_error::after {
    margin-top: 4px;
    max-height: 36px;
  }

  &.variant-formfield .display {
    height: 40px;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--background-border);

    & > div {
      height: 100%;
      justify-content: space-between;

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & .placeholder {
      color: var(--text-placeholder);
    }
  }

  &.variant-formfield.has_error .display {
    border-color: var(--text-error);
  }
}

.display {
  width: 100%;
  background: none;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;

  & > div {
    display: flex;
    align-items: center;
    gap: 2px;

    & .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.display_opened {
        transform: rotate(-180deg);
      }
    }

    & > span:not(.icon) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .placeholder {
    color: var(--text-primary);
  }
}

.body {
  border-radius: 12px;
  background: var(--main-white);
  padding: 8px 0px;
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  position: absolute;
  z-index: 9999;
}

.menu_item {
  list-style: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  gap: 8px;
  padding: 8px 12px;
  min-height: 36px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;

  &.default_hover:hover {
    background-color: var(--neutral-100);
  }
}

.selectable {
  & .menu_item {
    padding: 8px 36px 8px 12px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
