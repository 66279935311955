.b-period-years-picker__options {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &_years {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.b-period-years-picker__item {
  cursor: pointer;
  user-select: none;
  text-align: center;
  background-color: var(--neutral-100);
  border-radius: 12px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  &--selected {
    border: 1px solid var(--main-primary);
  }
}
