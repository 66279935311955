@import "../../assets/styles/colors.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0 30px;

  .back_link {
    display: inline-flex;
    align-items: center;
    margin-top: 16px;
    text-decoration: none;
    gap: 4px;
  }
}

.content {
  padding-top: 12px;
  max-width: 500px;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  & > .group {
    border-radius: 12px;
    background-color: $document-type-grey-grey-5;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: $document-type-grey-grey-15;
    }

    & > .collapsed {
      padding: 12px 16px;

      & > div {
        justify-content: space-between;
        width: 100%;
        gap: 12px;
      }

      & .collapsed__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 28px);
      }

      & .group_actions {
        display: flex;
        align-items: center;
        gap: 12px;
        opacity: 0;

        & .button {
          width: 16px;
          height: 16px;
        }
      }
    }

    & .expanded {
      padding: 0 16px 12px;

      & > .expanded_header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .create_question {
          height: 24px;
          padding: 10px 0px;

          &:hover,
          &:active {
            background: none;
          }
        }
      }
    }

    &:hover .group_actions {
      opacity: 1;
    }
  }
}

.questions_list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
}

.edit_question_form {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
