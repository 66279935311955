.owner_item {
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  & > .owner_name {
    width: calc(100% - 96px);

    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > .owner_actions {
    display: flex;
    align-items: center;
    gap: 4px;

    & .owner_btn {
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 50%;
      background: var(--controls-secondary-background);
    }
  }
}
