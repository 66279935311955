.mapboxgl-marker:has(.b-marker) {
  pointer-events: none !important;
}

.mapboxgl-marker:hover,
.mapboxgl-marker:has(.b-marker--selected) {
  z-index: 8;
}
.b-marker {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  pointer-events: none;
  transform: translateY(-32px);

  & .marker-primary-color {
    fill: #5aa3ff;
  }

  .mapboxgl-marker:hover:not(:has(&--selected)) .marker-primary-color {
    fill: #2983ff;
  }
  &--selected .marker-primary-color {
    fill: #f54343;
  }

  &-variant--default > * {
    cursor: pointer;
    pointer-events: all;
  }

  &__tooltip {
    background-color: rgba(0, 0, 0, 0.64);
    min-height: 32px;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    max-width: 200px;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
