.instructions {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;

  & > li {
    list-style: none;
    display: flex;
    gap: 12px;

    & > span {
      width: 24px;
      min-width: 24px;
      height: 24px;
      background: #e9edf3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--main-neutral);
    }

    & > p {
      margin: 2px 0;
    }
  }
}
