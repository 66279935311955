.b-stepper-button {
  display: block;
  width: 100%;
  color: var(--text-primary);
  text-decoration: none;

  & > div {
    height: 26px;
    justify-content: space-between;

    & > span {
      width: 27px;
      min-width: 27px;
      height: 26px;
    }

    &::after {
      content: "";
      display: block;
      width: calc(100% - 27px);
      height: 2px;
      border-radius: 1px;
      background: #a6b4c3;
    }
  }

  &--passed::after {
    background: #0bb261;
  }
  &--passed + span {
    color: #0bb261;
  }

  & > span {
    display: block;
    margin-top: 8px;
  }
}
