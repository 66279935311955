@import "../../../../../../assets/styles/colors.scss";

.table {
  margin: 0 -24px;

  & thead {
    white-space: nowrap;
  }
}

.completed {
  color: #25c168;
}
.not_completed {
  color: #ed0a34;
}

.table_container {
  width: 100%;
  overflow-x: auto;
}

.table_row {
  word-break: break-word;

  & > * {
    vertical-align: top;
  }

  & > * span {
    padding: 4px 0;
    display: block;
  }

  & > :first-child {
    width: 121px;
  }
  & > :nth-child(2) {
    min-width: 317px;
  }
  & > :nth-child(3) {
    width: 140px;
  }
  & > :nth-child(4) {
    min-width: 180px;
  }
  & > :nth-child(5) {
    width: 178px;
  }
  & > :last-child {
    width: 153px;
  }
}

.table_body > tr:not(:first-child) {
  border-top: 1px solid rgba(0, 17, 51, 0.12);
}
.table_body > tr:last-child {
  border-bottom: 1px solid rgba(0, 17, 51, 0.12);
}

.table_head tr > * {
  vertical-align: middle;
}
