.radio {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 4px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  & .input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;

    & input {
      cursor: inherit;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      margin: 0px;
      padding: 0px;
      z-index: 1;
    }

    & .control {
      width: 20px;
      min-width: 20px;
      height: 20px;
      display: block;
      border-radius: 999px;
      border: 2px solid var(--main-primary);
      outline: none;
    }
  }

  &.checked {
    & .control {
      border-width: 7px;
    }
  }

  &:hover .control,
  &:focus-visible .control {
    box-shadow: 0px 0px 0px 4px rgba(34, 109, 255, 0.1);
  }
}
