.b-gis-zhkh-status {
  display: flex;
  gap: 4px;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    min-width: 16px;
    height: 16px;

    &::after {
      content: "";
      background-color: currentColor;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
    }
  }
}
