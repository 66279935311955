.b-search-by-type {
  display: flex;
  align-items: center;
  align-items: center;
  border-radius: 10px;
  outline: 1px solid var(--background-border);

  & > span {
    width: 1px;
    height: 16px;
    border-radius: 1px;
    background: var(--background-border);
    margin: 0 12px;
  }

  &__select {
    display: flex;
    align-items: center;
    height: var(--base-field-height);
    gap: 2px;
    padding-left: 12px;

    & > svg {
      min-width: 16px;
    }
  }

  &__query {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 320px;

    &_icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
    }

    & > input {
      border: none;
      outline: none;
      height: var(--base-field-height);
      padding: 0 12px 0 20px;
      width: 100%;

      &::-webkit-input-placeholder {
        color: var(--text-placeholder);
      }
    }
  }
}
