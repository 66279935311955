.content {
  padding: 12px 0;
  max-width: 319px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item {
  border-radius: 20px;
  background: var(--royal-blue-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  text-decoration: none;
  padding: 16px 20px;
  border: 1px solid var(--main-white);
  box-sizing: border-box;
  transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    border-color: var(--main-primary);
  }

  & > .item_content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
