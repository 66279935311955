.users {
  &_head {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 0 12px;
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 0;
  }

  &_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
  }
}

.user_item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.me {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid rgba(0, 17, 51, 0.24);

  & .me_content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
