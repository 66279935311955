@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-Medium.eot");
  src: local("SF Pro Display Medium"), local("SFProDisplay-Medium"),
    url("../fonts/SFPro/SFProDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-Medium.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-Medium.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-RegularItalic.eot");
  src: local("SF Pro Display Regular Italic"), local("SFProDisplay-RegularItalic"),
    url("../fonts/SFPro/SFProDisplay-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-RegularItalic.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-RegularItalic.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-Light.eot");
  src: local("SF Pro Display Light"), local("SFProDisplay-Light"),
    url("../fonts/SFPro/SFProDisplay-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-Light.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-Light.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-Bold.eot");
  src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"),
    url("../fonts/SFPro/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-Bold.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-SemiboldItalic.eot");
  src: local("SF Pro Display SemiboldItalic"), local("SFProDisplay-SemiboldItalic"),
    url("../fonts/SFPro/SFProDisplay-SemiboldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-SemiboldItalic.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-SemiboldItalic.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-LightItalic.eot");
  src: local("SF Pro Display LightItalic"), local("SFProDisplay-LightItalic"),
    url("../fonts/SFPro/SFProDisplay-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-LightItalic.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-LightItalic.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-MediumItalic.eot");
  src: local("SF Pro Display MediumItalic"), local("SFProDisplay-MediumItalic"),
    url("../fonts/SFPro/SFProDisplay-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-MediumItalic.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-MediumItalic.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-Semibold.eot");
  src: local("SF Pro Display Semibold"), local("SFProDisplay-Semibold"),
    url("../fonts/SFPro/SFProDisplay-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-Semibold.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-Semibold.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-Regular.eot");
  src: local("SF Pro Display Regular"), local("SFProDisplay-Regular"),
    url("../fonts/SFPro/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFPro/SFProDisplay-BoldItalic.eot");
  src: local("SF Pro Display BoldItalic"), local("SFProDisplay-BoldItalic"),
    url("../fonts/SFPro/SFProDisplay-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFPro/SFProDisplay-BoldItalic.woff2") format("woff2"),
    url("../fonts/SFPro/SFProDisplay-BoldItalic.woff") format("woff"),
    url("../fonts/SFPro/SFProDisplay-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 400;
  font-style: normal;
  src: local("SF Pro Text Regular"),
    url("../fonts/SFProText/SF-Pro-Text-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 400;
  font-style: italic;
  src: local("SF Pro Text Regular Italic"),
    url("../fonts/SFProText/SF-Pro-Text-RegularItalic.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 500;
  font-style: normal;
  src: local("SF Pro Text Medium"),
    url("../fonts/SFProText/SF-Pro-Text-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 500;
  font-style: italic;
  src: local("SF Pro Text Medium Italic"),
    url("../fonts/SFProText/SF-Pro-Text-MediumItalic.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 600;
  font-style: normal;
  src: local("SF Pro Text Semibold"),
    url("../fonts/SFProText/SF-Pro-Text-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 600;
  font-style: italic;
  src: local("SF Pro Text Semibold Italic"),
    url("../fonts/SFProText/SF-Pro-Text-SemiboldItalic.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: bold;
  font-style: normal;
  src: local("SF Pro Text Bold"), url("../fonts/SFProText/SF-Pro-Text-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: bold;
  font-style: italic;
  src: local("SF Pro Text Bold Italic"),
    url("../fonts/SFProText/SF-Pro-Text-BoldItalic.otf") format("opentype");
}
