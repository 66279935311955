.b-bubble {
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    padding: 40px;
  }
}

.enter {
  transform: scale(0);
}

.enterActive {
  transition: transform 750ms ease;
  transform: scale(1);
}

.exit {
  transform: scale(1);
}

.exitActive {
  transition: transform 1s ease;
  transform: scale(0);
}
