.root {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.154px;
  margin-bottom: 4px;

  & > *:not(label) {
    vertical-align: middle;
    margin-left: 4px;
    display: -webkit-inline-box;
    margin-top: -2px;

    & > svg {
      display: flex;
    }
  }
}
