.base {
  display: flex;
  align-items: center;

  & > .base_avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-primary);
    overflow: hidden;

    & > img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  & .clickable {
    cursor: pointer;
  }
}
