.content {
  padding: 12px 0;
}

.table {
  margin: 0 -24px;
}

.table tbody tr {
  cursor: pointer;
  user-select: none;
}

.table_container {
  width: 100%;
  overflow-x: auto;
}

.table_row {
  white-space: nowrap;

  & > :not(:last-child) {
    min-width: 116px;
  }

  .table_cell_number {
    width: 116px;
  }
  .table_cell_name {
    max-width: 30%;
  }
  .table_cell_member {
    width: 240px;
  }
  .table_cell_decision {
    width: 158px;
  }
  & > :last-child {
    width: 52px;
  }
}

.decision_tooltip {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > div {
    gap: 10px;
  }
}

.empty {
  background: white !important;

  & td > div {
    padding: 20px;
  }
}
