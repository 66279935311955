.b-snt-passport-years-filter {
  padding: 16px 0;

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__item {
    height: 40px;
    background-color: var(--neutral-100);
    border-radius: 24px;
    color: var(--text-secondary);
    padding: 12px 16px;
    text-decoration: none;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: var(--text-primary);
      background-color: var(--neutral-200);
    }

    &--active,
    &--active:hover {
      background-color: #dce8fe;
      color: var(--main-primary);
    }
  }
}
