.table {
  margin: 8px -24px 0;
}
.table_container {
  width: 100%;
  overflow-x: auto;

  & table {
    overflow: hidden;
  }
}

.table_row {
  & > :first-child {
    min-width: 380px;
  }
  & > :nth-child(2) {
    min-width: 254px;
  }
  & > :nth-child(3) {
    min-width: 232px;
  }
  & > :nth-child(4) {
    min-width: 165px;
  }
  & > :nth-child(5) {
    min-width: 180px;
  }
  & > :nth-child(6) {
    min-width: 202px;
  }
  & > :nth-child(8) {
    white-space: nowrap;
  }
  & > :last-child {
    width: 52px;
  }

  .table_row_status {
    max-width: 200px;
  }
  .table_row_status > div {
    overflow: hidden;
    width: 100%;
  }
  .table_row_status > div > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table_row_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > svg {
      position: absolute;
    }
  }
}

.table_body .table_row_status {
  white-space: nowrap;
}

.table_body > tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 17, 51, 0.12);
}

.table_body > tr {
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: #e7ecf0;
  }

  &:active {
    background-color: #dde3e7;
  }
}

.empty {
  background: white !important;

  & td > div {
    padding: 20px;
  }
}

.loading_more {
  background: white !important;

  & td > div {
    padding: 8px;
  }
}
