.root {
  position: fixed;
  top: 88px;
  right: 24px;
  max-height: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container {
  box-shadow: 0px 1px 3px 0px rgba(0, 17, 51, 0.08), 0px 5px 10px 0px rgba(0, 17, 51, 0.16);
  border-radius: 8px;
  background: var(--main-white);
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  max-width: 420px;

  &.w-close {
    width: 316px;
  }

  & > div {
    display: flex;
    gap: 16px;

    & > svg {
      min-width: 24px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 2px 0;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;

  & > span {
    display: flex;
    align-items: center;
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
}
