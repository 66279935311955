.b-set-administrator {
  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__form {
    width: 100%;
    max-width: 520px;
    margin-top: 24px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 100%;
      flex-basis: 100%;
    }
  }
}
