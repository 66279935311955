.label {
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
}

.input {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(29, 29, 31, 0.14);
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
  margin: 0;

  &:checked + .check {
    opacity: 1;
    background: var(--icons-accent);
    width: 20px;
    height: 20px;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    box-shadow: 0px 0px 0px 3px rgba(104, 42, 250, 0.1);
  }
}

.check {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
