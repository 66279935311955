.navigation {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.b-unread-chats {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: var(--badge-negative);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
// .disabled_message {
//   @include sf-text-regular;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: -0.154px;
//   color: $secondaryNew;
//   margin: 0;
//   padding: 12px;
// }
