.b-appeal-dialog {
  background-color: var(--background-page);
  border-radius: var(--base-card-border-radius);
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--selected {
    outline: 2px solid var(--main-primary);
    background-color: var(--royal-blue-200);
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
  }

  &__message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 32px;
  }

  &__unread {
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-color: var(--badge-negative);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
