@import "../../assets/styles/common.scss";
@import "../../assets/styles/colors.scss";

.react-datepicker {
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16),
    0px 0px 1px 0px rgba(0, 17, 51, 0.16);
  border-radius: 12px;
  background: #fff;
  border: none;
  padding: 8px 10px;
  color: $text-primary;

  &-wrapper {
    width: 100%;
  }

  &__triangle {
    display: none;
  }

  &__header {
    background: transparent;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding: 0;
    border-bottom: none;

    &:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 16px;
    }
  }

  &__day-names,
  &__week {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    padding-top: 4px;
  }

  &__day-name,
  &__day,
  &__time-name {
    @include sf-text-regular;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    text-transform: capitalize;
    margin: 0 2px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__day-name {
    color: $text-ghost;
  }

  &__day {
    color: $text-primary;
    border-radius: 6px;
  }

  &__day:not(&__day--disabled, &__day--selected):hover,
  &__day--keyboard-selected {
    background: $controls-background-ghost-hover;
    border-radius: 6px;
  }

  &__day--disabled {
    color: $text-ghost;
  }

  &__day--selected,
  &__day--selected:hover {
    @include sf-text-bold;

    border: 1.5px solid $main-primary;
    background: white;
    color: $main-primary;
  }

  &__day--today:not(&__day--selected, &__day--disabled),
  &__day--today:not(&__day--selected, &__day--disabled):hover {
    @include sf-text-bold;

    background: #fbc5ef;
    color: $text-primary;
  }

  &__month {
    margin: 0;
  }

  &__children-container {
    clear: both;
    width: 100%;
    padding: 4px 0 0;
    margin: 0;
  }

  &__today-button {
    border-top: none;
    background: white;
    clear: both;
    width: 100%;
    padding: 4px 0 0;
    margin: 0;
  }

  // OLD DESIGN
  &__current-month {
    text-transform: capitalize;
    font-size: 16px;
  }

  &__navigation {
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    top: 9px;

    &--next {
      right: 16px;
    }

    &--previous {
      left: 16px;
    }

    &-icon {
      &::before {
        border-width: 2px 2px 0 0;
        border-color: rgba(0, 0, 0, 0.38);
        top: 8px;
      }

      &--next::before {
        left: -5px;
      }

      &--previous::before {
        left: -5px;
      }
    }
  }

  &__input-time-container {
    margin: 5px 0 0;
    .react-datepicker-time__caption {
      display: none;
    }
  }

  &-time__input {
    width: 100%;
    margin-left: 0 !important;
    background: #f5f5f5;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 36px;
    text-align: center;

    input {
      width: 100% !important;
    }

    &-container {
      width: 100%;
    }
  }

  &__month-select,
  &__year-select {
    background: #f5f5f5;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 36px;
    text-align: center;
    padding: 0 6px;
  }
}
