@import "../../../../assets/styles/colors.scss";
@import "../../../../assets/styles/common.scss";

.content {
  display: flex;

  & .form {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-right: 1px solid #d6d9de;
    gap: 20px;

    &.snt_form {
      border-right: none;
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  & .addresses_wrapper {
    position: relative;
    padding: 0;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    & .addresses {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding-top: 16px;
      padding-right: 7px;
      overflow: hidden;

      & .addresses_label {
        padding-left: 24px;
      }

      & .addresses_error {
        @include sf-text-regular;

        padding: 0 24px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.154px;
        margin: 0 0 4px;
        color: $red;
      }

      & .addresses_list {
        width: 100%;
        height: calc(100% - 22px);
        padding-bottom: 16px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding-left: 24px;

        &::-webkit-scrollbar {
          width: 6px;
          padding: 7px;
        }

        &::-webkit-scrollbar-track {
          background: white;
        }

        &::-webkit-scrollbar-thumb {
          background: #ebeff2;
          border-radius: 4px;
          width: 6px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #d8d8db;
        }
      }

      &.has_error .addresses_list {
        height: calc(100% - 44px);
      }
    }
  }
}

.row {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 40px;

  .label {
    @include sf-text-regular;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: $textPrimary;

    &.label_all {
      @include sf-text-medium;
    }
  }
}
