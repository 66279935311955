.b-chat-messages-group {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__date {
    padding: 16px 0 12px;
    display: flex;
    justify-content: center;
    background-color: var(--background-content);
    position: sticky;
    top: 0;
  }

  &__list {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }
}
