.b-chat-input {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  &__field {
    width: 100%;
    display: flex;
    align-items: flex-end;
    max-height: 72px;

    & textarea {
      min-height: auto;
      max-height: 46px;
    }
  }

  &__submit {
    height: 44px !important;
  }
}
