.access {
  padding: 16px 12px !important;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    border-radius: 20px;

    &:hover {
      background-color: rgba(180, 184, 204, 0.2);
    }

    &:active {
      background-color: rgba(180, 184, 204, 0.3);
    }
  }

  & .access_icon {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 16px;
    background: rgba(180, 184, 204, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .access_base {
    width: calc(100% - 88px);
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  & .access_chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}

.user {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.set_access_modal {
  position: fixed;
  width: 520px;
  border-radius: 16px;
  background: var(--main-white);
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  z-index: 1;
  height: auto;
  top: 89px;
  padding: 0 !important;

  & .set_access_content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  & .role_select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .clear_btn {
    cursor: pointer;
  }
}
