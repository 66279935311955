.card {
  border-radius: 20px;
  background: #f7f1fe;
  padding: 16px;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.avatar {
  width: 64px;
  min-width: 64px;
  height: 64px;
  position: relative;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-primary);
    overflow: hidden;
    width: 100%;
    height: 100%;

    & > img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  & .role {
    position: absolute;
    bottom: -2px;
    right: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
  }
}

.user_name {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
