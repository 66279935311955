.b-draft-occ {
  padding: 8px 12px;
  border-radius: 12px;
  background: #edeef2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: currentColor;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: #e6e7ed;
  }

  & > span > span {
    margin-top: 4px;
    color: var(--text-placeholder);
    gap: 4px;
  }
}
