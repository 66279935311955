.wrapper {
  background-color: var(--background-page);
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    padding: 16px 24px;
    border-radius: 16px;
    background: var(--background-content);
  }
}
