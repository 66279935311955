.b-create-question-form {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;

  &__files-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
  }

  &__submit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
