.b-sidebar {
  display: flex;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: var(--background-content);
  border-right: 1px solid var(--background-border);

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    padding: 8px 16px 16px;
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

  & footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 12px;
  }

  &__toolbar {
    padding: 12px 16px;
    border-radius: 16px;
    background-color: var(--royal-blue-100);
    margin-bottom: 16px;

    &:hover {
      background-color: var(--royal-blue-200);
    }

    &_snt {
      display: flex;
      flex-direction: column;
      gap: 4px;

      & > div,
      & > a {
        gap: 8px;
      }
    }

    &_uk {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
