.container {
  &.default-top-offset {
    margin-top: 8px;
  }
  &.default-horizontal-offset {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

.row {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  & > .cell:first-child {
    padding-left: 24px;
  }

  & > .cell:last-child {
    padding-right: 20px;
  }

  &.highlight-on-hover:hover,
  &.clickable:hover {
    background-color: var(--neutral-100);
  }

  &.clickable {
    cursor: pointer;

    &:active {
      background-color: #dde3e7;
    }
  }
}

.cell {
  display: table-cell;
  vertical-align: inherit;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.154px;
  text-align: left;
  height: 40px;
  color: var(--text-primary);
}

.head {
  display: table-header-group;

  &.nowrap {
    white-space: nowrap;
  }

  & .row {
    background: #cbd5de;
  }

  & .cell {
    padding: 10px 16px 10px 8px;
    color: var(--text-black);
  }
}

.body {
  display: table-row-group;

  &.empty {
    background: white !important;

    & td > div {
      padding: 20px;
    }
  }

  &.default-divider > tr {
    background: white;
  }
  &.default-divider > tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 17, 51, 0.12);
  }

  & .cell {
    padding: 8px;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 24px;
  gap: 24px;

  & > .tools,
  & > .tools > div {
    display: flex;
    align-items: center;
    gap: 8px;

    & > .tool_item {
      display: flex;
      align-items: center;
    }

    & > .separator {
      width: 1px;
      height: 20px;
      border-radius: 1px;
      background: rgba(0, 17, 51, 0.32);
      margin: 0 2px;
    }
  }
}
