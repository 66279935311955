.import_file_errors {
  display: flex;
  flex-direction: column;

  & > div {
    & > p {
      padding: 4px 0;
    }

    &:not(:last-child)::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: rgba(0, 17, 51, 0.24);
      margin: 12px 0;
    }
  }
}

.import_file_errors_content {
  background: #f5f5f7;
  box-shadow: 0px 1px 2px 0px rgba(0, 17, 51, 0.04) inset, 0px 2px 4px 0px rgba(0, 17, 51, 0.08) inset;
}

.import_file_errors_subtitle {
  padding-left: 56px;
  color: var(--text-primary);
}

.header {
  padding: 20px 24px 16px 24px;

  & > div {
    gap: 16px;

    & > div {
      gap: 16px;
    }
  }

  & .header_title {
    padding: 8px 0;

    & > p {
      margin-top: 4px;

      & .errors_count {
        color: #f20000;
      }
    }
  }
}

.close_btn {
  min-width: 24px;
  cursor: pointer;
}
