.box {
  min-width: 384px;
}

.templates_management {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  text-decoration: none;
  color: var(--main-primary);
}

.templates {
  padding: 0 12px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 230px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    width: 14px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 14px;
    background: #d6d9de;
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }

  & > p {
    color: var(--text-secondary);
  }
}

.templates_accordion {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.collapsed {
  padding: 4px 0;
}

.templates_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.template_question {
  padding: 8px 12px;
  border-radius: 10px;
  background: var(--document-type-grey-grey-5);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  & > span {
    text-overflow: ellipsis;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 40px;
    overflow: hidden;
  }

  &.multiline > span {
    background: linear-gradient(180deg, #2e2d2d 0%, rgba(46, 45, 45, 0.2) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & > svg {
    min-width: 16px;
  }

  &:hover {
    background: var(--document-type-grey-grey-10);
  }
}
