.container {
  position: relative;

  & > div {
    display: inline-flex;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 0;
}

.form {
  position: relative;

  &::after {
    content: attr(data-error);
    display: block;
    color: var(--text-error);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    max-height: 0;
    margin-top: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  &.has_error::after {
    margin-top: 4px;
    max-height: 36px;
  }

  & > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: 1;
  }

  & > label {
    width: 100%;
    border-radius: 16px;
    padding: 32px;
    border: 1px dashed #083da4;
    background: #ecf4ff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.drag_active {
      background: #e2eefe;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      & span[role="button"] {
        cursor: pointer;
        user-select: none;
      }
    }
  }

  & .drag_zone {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.files {
  padding: 24px 0 0;
}
