.b-chat-message {
  width: 100%;

  &__wrapper {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
  }

  &__card {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: var(--background-page);
    border-radius: 20px 20px 20px 4px;
    max-width: 400px;
    width: 75%;
  }

  &__text {
    white-space: pre-line;
    word-wrap: break-word;
  }

  &--me &__wrapper {
    justify-content: flex-end;
  }
  &--me &__card {
    background-color: rgba(34, 109, 255, 0.1);
    border-radius: 20px 20px 4px 20px;
  }

  &__datetime {
    display: flex;
    justify-content: flex-end;
  }
}
