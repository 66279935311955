.b-create-order-form {
  width: 100%;
  max-width: 1060px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;

  & .col-12 {
    width: 100%;
    flex-basis: 100%;
    min-width: 520px;
  }

  & .col-6 {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    min-width: 520px;
  }

  & .col-3 {
    width: calc(25% - 15px);
    flex-basis: calc(25% - 15px);
    min-width: 250px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
