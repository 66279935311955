.b-navigation-item {
  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 12px;
    height: 44px;
    padding: 0 12px;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }

    &_chevron {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      min-width: 16px;
      height: 16px;
    }

    &:not(&--active):not(&--expanded):hover {
      background-color: var(--controls-secondary-controls);
    }

    &--active {
      background-color: var(--controls-neutral-controls);
    }
    &--active &_icon svg path {
      fill: var(--icons-white);
    }
    &--active div > span {
      color: var(--text-white);
    }
  }

  &__children {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px 0 0;
  }
}
