.details {
  background: var(--document-type-grey-grey-5);
  border-radius: 16px;
  padding: 20px;
  max-width: 517px;
  display: table;

  & > div {
    display: table-row;

    &:not(:first-child) > span {
      padding-top: 12px;
    }

    & > span {
      display: table-cell;

      &:first-child {
        padding-right: 12px;
      }
    }
  }
}
