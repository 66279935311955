.b-map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;

  & > #map {
    width: 100%;
    height: 100%;
  }
}

.map-state--add-marker.map-state--add-marker .mapboxgl-canvas,
.map-state--change-snt-geo .mapboxgl-canvas {
  cursor: crosshair;
}

.b-map-zoom-controls {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__btn {
    width: 48px;
    height: 48px !important;
    background-color: var(--main-white);
    box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;

    &--disabled {
      pointer-events: none;

      & svg {
        opacity: 0.5;
      }
    }

    &:hover svg path {
      fill: var(--controls--primary-selection);
    }
  }
}

.b-cursor-tooltip {
  position: fixed;
  transform: translate3d(-46px, -66px, 0);
  white-space: nowrap;
}
