.b-snt-summary-container {
  position: relative;
  width: 689px;
  height: 487px;
}

.b-snt-summary-header {
  background: white;
  padding: 24px 24px 0;
  border-radius: 0 0 20px 20px;

  &__content {
    padding: 16px 0;
  }
}

.b-snt-summary-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__graphics {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    min-width: 737px;
    min-height: 535px;
  }
  &__bottom-text {
    display: flex;
    align-items: center;
    min-height: 64px;
    padding: 24px;
  }
}

.b-snt-summary-item {
  &--sections-owners {
    left: 0;
    top: 51px;
  }
  &--lokolo-users {
    right: 130px;
    top: 0;
    transition-delay: 100ms;
  }
  &--sections-garden-owners {
    right: 0;
    bottom: 0;
    transition-delay: 150ms;
  }

  &--sections-owners &__content > span {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.36px;
  }

  &--lokolo-users &__content > span {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.2px;
  }

  &--sections-garden-owners &__content > span {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.2px;
  }
}
