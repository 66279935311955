.layout {
  min-height: 100vh;
}

.wrapper {
  display: flex;
  padding-top: var(--header-height);
  min-height: 100%;

  & > aside {
    position: fixed;
    left: 0;
    top: var(--header-height);
    width: var(--aside-width);
    height: calc(100% - var(--header-height));
    z-index: 1;
  }

  & > div {
    position: relative;
    padding-left: var(--aside-width);
    width: 100%;
    height: 100%;
  }
}
