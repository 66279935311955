@import "../../assets/styles/colors.scss";
@import "../../assets/styles/common.scss";

.toggler {
  @include sf-semibold;

  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & .actions {
    display: flex;
    gap: 12px;
  }
}

.container {
  padding: 0 44px;

  & > div {
    padding-bottom: 20px;
  }

  & > .item {
    padding: 12px 0;
    gap: 4px;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.442px;

    & span {
      @include sf-text-regular;

      display: block;
      color: $lightText;
    }

    & p {
      @include sf-text-semibold;

      margin: 0;
      color: $navyHead;
    }
  }
}
