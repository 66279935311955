.b-interactive-eyes {
  &__pupil {
    animation: Eyes 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes Eyes {
  0%,
  10%,
  40%,
  60% {
    transform: translate3d(0, 0, 0);
  }
  20%,
  30% {
    transform: translate3d(2px, -23px, 0);
  }
}
