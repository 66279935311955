.p-institution-snt-board {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 0;

  &__publication {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}
