.success-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px 0;

  &__role {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 12px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    &_uk {
      background: #dfeaff;
    }

    &_snt {
      background: #ffdfee;
    }
  }
}

.create_user {
  & .create_user_form {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    padding: 16px 0;

    & > div {
      flex-basis: calc(50% - 8px);

      &.full_field {
        flex-basis: 100%;
      }
    }

    & .checkbox_field {
      display: flex;
      align-items: center;
      height: 40px;
      gap: 10px;
    }
  }
}

.b-set-role-form {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div > p {
    margin-bottom: 4px;
  }

  &__role-field {
    width: 100%;
    justify-content: space-between;

    &_value {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.154px;
      width: calc(100% - 16px);
    }

    &_chevron {
      display: flex;
      align-items: center;

      & > * {
        display: flex;
        align-items: center;
      }
    }
  }

  &__phone-clear {
    cursor: pointer;
    user-select: none;
  }
}
