.b-chart-card {
  background-color: var(--main-white);
  border-radius: var(--base-card-border-radius);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.b-chart-card--header {
  display: flex;
  align-items: center;
  gap: 4px;

  & > *:not(h4) {
    vertical-align: middle;
    margin-left: 4px;
    display: -webkit-inline-box;

    & > svg {
      display: flex;
    }
  }
}
