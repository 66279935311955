.header {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
  }

  & .back_link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    gap: 4px;
    padding: 2px 0px;
    cursor: pointer;
  }

  &_actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
