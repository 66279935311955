.headline_alert {
  border-radius: var(--base-card-border-radius);
  background: linear-gradient(90deg, #e9e8ff 0%, #e0f2ff 100%), #f7ebff;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  gap: 2px;

  & .content {
    width: calc(100% - 18px);
    display: flex;
    gap: 12px;

    & .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .text {
      width: calc(100% - 36px);

      & > h4 {
        padding: 2px 0;
      }

      & > h4 + div {
        margin-top: 4px;
      }
    }
  }

  .close {
    min-width: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }
}
