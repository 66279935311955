.root {
  display: flex;
  flex-direction: column;

  & .collapsed {
    display: flex;
    cursor: pointer;
    user-select: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    & .collapsed_box {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    & .icon {
      display: flex;
      align-items: center;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    & .actions {
      margin-left: auto;
    }
  }

  & .expanded {
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
  }
}

.custom_collapsed {
  cursor: pointer;
  user-select: none;

  & [role="spinbutton"] {
    display: flex;
    align-items: center;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(0);
  }
  &.opened [role="spinbutton"] {
    transform: rotate(90deg);
  }

  &.up-down-transtition.opened {
    & [role="spinbutton"] {
      transform: rotate(180deg);
    }
  }
}
