.b-snt-passport-block {
  background: #f2f6fa;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
      background-color: var(--main-white);
      padding: 20px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
