.tabs {
  display: flex;
  padding: 16px 0;
  gap: 8px;

  & > a {
    border-radius: 24px;
    color: var(--text-secondary);
    background-color: var(--neutral-100);
    padding: 12px 16px;
    text-decoration: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      color: var(--text-primary);
      background-color: var(--neutral-200);
    }

    &.active_tab {
      color: var(--main-primary);
      background-color: #dce8fe;
    }
  }
}

.content {
  padding: 12px 0;
}
