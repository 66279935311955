@import "../../assets/styles/colors.scss";
@import "../../assets/styles/common.scss";

.base {
  width: 100%;
  position: relative;

  &::after {
    content: attr(data-error);
    display: block;
    color: $red;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    max-height: 0;
    margin-top: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  &.has_error .display {
    border-color: $red;
  }

  &.has_error::after {
    margin-top: 4px;
    max-height: 36px;
  }
}

.display {
  @include sf-text-regular;

  width: 100%;
  border-radius: 10px;
  padding: 12px;
  height: 40px;
  background: none;
  box-shadow: none;
  border: 1px solid rgba(43, 57, 73, 0.16);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.154px;

  &.placeholder {
    color: #818c99;
  }

  & svg[role="button"] {
    cursor: pointer;
    user-select: none;
  }
}

.body {
  border-radius: 12px;
  background: $primary;
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  position: absolute;
  z-index: 9999;
}

.search {
  padding: 12px 12px 0;
}

.list {
  margin-top: 8px;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 12px;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__emty {
    padding: 12px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: $textSecondary;
  }
}

.item {
  border-radius: 8px;
  min-height: 44px;
  padding: 12px;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.item:hover {
  background-color: #dce7fe;
}
