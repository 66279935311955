:root {
  // Main
  --main-primary: #226dff;
  --main-black: #000000;
  --main-white: #ffffff;

  // Icons
  --icons-black: #2c2d2e;
  --icons-primary: #818c99;
  --icons-secondary: #99a2ad;
  --icons-ghost: #b8c1cc;
  --icons-accent: #226dff;
  --icons-white: #ffffff;

  // Text
  --text-black: #000000;
  --text-white: #ffffff;
  --text-primary: #2e2d2d;
  --text-secondary: #818c99;
  --text-tertiary: #99a2ad;
  --text-subhead: #6d7885;
  --text-ghost: #b4bac2;
  --text-placeholder: #818c99;
  --text-normal: #18a2f2;
  --text-success: #10c44c;
  --text-warning: #f6a200;
  --text-error: #f20000;
  --text-link: #226dff;
  --text-link-visited: #98bbff;

  // Background
  --background-content: #ffffff;
  --background-page: #e6e8eb;
  --background-content-tint: #f2f6fa;
  --background-overlay: rgba(0, 0, 0, 0.4);
  --background-stripe: rgba(43, 57, 73, 0.08);
  --background-border: rgba(43, 57, 73, 0.16);
  --background-shadow: #18212b;

  // Royal Blue
  --royal-blue-100: #eff7ff;
  --royal-blue-200: #daecff;
  --royal-blue-300: #8cc1ff;
  --royal-blue-400: #5aa3ff;
  --royal-blue-500: #2983ff;
  --royal-blue-600: #0063f6;
  --royal-blue-700: #0052cc;
  --royal-blue-800: #0041a2;
  --royal-blue-900: #003077;
  --royal-blue-1000: #001f4c;

  // Neutral
  --neutral-100: #f5f6f8;
  --neutral-200: #e7ecf0;
  --neutral-300: #cbd5de;
  --neutral-400: #a6b4c3;
  --neutral-500: #93a3b4;
  --neutral-600: #6c7f94;
  --neutral-700: #546880;
  --neutral-800: #3f5066;
  --neutral-900: #2b3949;
  --neutral-1000: #18212b;

  // Neutral Pale
  --neutral-alpha-4: rgba(43, 57, 73, 0.04);
  --neutral-alpha-8: rgba(43, 57, 73, 0.08);
  --neutral-alpha-16: rgba(43, 57, 73, 0.16);
  --neutral-alpha-24: rgba(43, 57, 73, 0.24);
  --neutral-alpha-32: rgba(43, 57, 73, 0.32);
  --neutral-alpha-40: rgba(43, 57, 73, 0.4);
  --neutral-alpha-48: rgba(43, 57, 73, 0.48);
  --neutral-alpha-64: rgba(43, 57, 73, 0.64);
  --neutral-alpha-80: rgba(43, 57, 73, 0.8);
  --neutral-alpha-100: rgba(43, 57, 73, 1);

  // Controls
  --controls-focus: #226dff;
  --controls-primary-background: #226dff;
  --controls-primary-foreground: #ffffff;
  --controls-primary-controls-hover: #175ce0;
  --controls-secondary-controls: #e9edf3;
  --controls-secondary-controls-hover: #e0e5ef;
  --controls-negative-foreground: #f20000;
  --controls-negative-controls-hover: #e40a34;
  --controls-positive-controls: #10c44c;
  --controls-positive-controls-hover: #0ba65b;
  --controls-neutral-controls: #2e2d2d;
  --controls-neutral-controls-hover: #1f1f1f;

  // Badge
  --badge-positive: #10c44c;
  --badge-positive-pale: rgba(29, 237, 97, 0.12);
  --badge-negative: #f20000;
  --badge-negative-pale: rgba(242, 0, 0, 0.08);
  --badge-neutral: #001a34;
  --badge-neutral-pale: rgba(0, 48, 120, 0.04);
  --badge-warning: #f6a200;
  --badge-warning-pale: rgba(246, 162, 0, 0.12);
  --badge-blue: #18a2f2;
  --badge-blue-pale: rgba(24, 162, 242, 0.08);
  --badge-promo: linear-gradient(92.77deg, #ff6445 10.26%, #ff3f97 89.74%);

  // Avatar Initials
  --avatar-initials-deep-blue: linear-gradient(0deg, #4c71f6, #4c71f6),
    linear-gradient(108deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --avatar-initials-blue: linear-gradient(0deg, #249ff8, #249ff8),
    linear-gradient(108deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --avatar-initials-pink: linear-gradient(0deg, #f64c7f, #f64c7f),
    linear-gradient(108deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --avatar-initials-violet: linear-gradient(0deg, #9637f4, #9637f4),
    linear-gradient(108deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --avatar-initials-yellow: linear-gradient(0deg, #f4b30e, #f4b30e),
    linear-gradient(108deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --avatar-initials-red: linear-gradient(0deg, #f8244a, #f8244a),
    linear-gradient(108deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
}

.color--text-link {
  color: var(--text-link);

  &:visited {
    color: var(--text-link-visited);
  }
}
