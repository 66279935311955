.b-drafts {
  &__badge {
    padding: 12px 0;
  }

  &__header {
    padding: 12px 0;
  }

  &__list {
    max-width: 275px;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__loading {
    padding: 48px 24px;
  }
}
