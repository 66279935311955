.display {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  & .display_address {
    gap: 8px;
    color: black;
    cursor: pointer;

    &_chevron {
      min-width: 16px;
      width: 16px;
      height: 16px;
      display: inline-flex;
      align-items: center;
      transform: translateY(3px);
      margin-left: 4px;
    }
  }

  &.formfield {
    width: 100%;

    & > .display_address {
      height: var(--base-field-height);
      border-radius: var(--base-field-border-radius);
      padding: 0 12px;
      font-size: 14px;
      letter-spacing: -0.154px;
      border: 1px solid var(--background-border);
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }

    & .display_address_chevron {
      transform: translateY(0);
      margin-left: 0;
    }
  }

  &.disabled > .display_address {
    opacity: 0.5;
    pointer-events: none;
  }
}

.body {
  border-radius: 12px;
  background: var(--main-white);
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  position: absolute;
  min-width: 420px;
  z-index: 9999;

  & .search {
    border-bottom: 1px solid rgba(0, 17, 51, 0.24);
    padding: 8px 12px;
  }

  & .list {
    padding: 8px 0 8px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 56px;
    max-height: 224px;

    &::-webkit-scrollbar {
      width: 12px;
      height: 14px;
      position: relative;
    }

    &::-webkit-scrollbar-track {
      width: 12px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 12px;
      background: #d6d9de;
      border: 3px solid transparent;
      background-clip: padding-box;
      border-radius: 10px;
    }
  }
}
