.b-chat-user-details {
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
  }

  &__profile {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &_divider {
      width: 100%;
      height: 1px;
      background-color: var(--background-stripe);
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
  }
}
