.b-statistics {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
}
.b-statistics-wrapper {
  padding: 0 24px 24px;
}

.b-top {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 0 0;

  &__cards > div {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    & > div {
      padding: 10px;
      flex: 1;
      flex-basis: 50%;
    }
  }
}

.b-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0;

  &--titled {
    padding: 32px 0 0;
  }

  &__cards > div {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    & > div {
      padding: 10px;
      flex: 1;
      flex-basis: 33.33%;
    }
  }
}

.b-card {
  background: var(--main-white);
  height: 100%;
  display: flex;

  &--big {
    border-radius: 16px;
    padding: 20px;

    &:has([class^="headline-h1"]) {
      padding-bottom: 0;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;

      & [class^="headline-h1"],
      & [class*=" headline-h1"] {
        font-size: 56px;
        line-height: 120px;
      }
    }

    & .b-card--has-percent > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &--small {
    border-radius: 12px;
    padding: 12px;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      width: 100%;
    }

    & .b-card--has-percent > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__percentage {
    background-color: rgba(13, 194, 104, 0.1);
    padding: 4px 8px;

    & [class^="headline-h4"],
    & [class*=" headline-h4"] {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.38px;
    }
  }

  &--small &__percentage {
    border-radius: 8px;
  }

  &--big &__percentage {
    border-radius: 12px;
  }

  &--work-scope {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__stick {
      width: 100%;
      height: 1px;
      background-color: var(--stripe-transparent);
    }
  }
}
