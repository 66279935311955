@import "../../../../../../assets/styles/colors.scss";

.owners {
  margin: 0 -24px;
}

.table_container {
  width: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
  width: 100%;
  display: flex;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    width: 10px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 10px;
    background: #d6d9de;
    border: 2px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }

  & > * {
    transform: rotateX(180deg);
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.table_shadowed {
  & .sticky {
    box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.1);
  }
}

.first_col {
  min-width: 146px;
}

.second_col {
  white-space: nowrap;
  padding-right: 24px !important;
}

.table_header_row {
  white-space: nowrap;
}

.table_head > tr > * {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #cbd5de;
}

.table_body > tr {
  background: white;
}
.table_body > tr > * {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: white;
}
.table_body > tr:nth-of-type(even) > * {
  background: #f5f6f8;
}

.colgroup {
  border: 1px solid red;
}
