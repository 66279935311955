.b-autocomplete-dropdown {
  &__field {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    & > div {
      width: 100%;
    }

    & > svg {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__options {
    border-radius: 12px;
    background: var(--main-white);
    position: absolute;
    z-index: 9999;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;

    &_item {
      width: 100%;
      cursor: pointer;

      & > div {
        border-radius: 8px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 4px 8px;
      }

      &:hover > div {
        background-color: var(--neutral-100);
      }
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
  }

  &::after {
    content: attr(data-error);
    display: block;
    color: var(--text-error);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    max-height: 0;
    margin-top: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  &--has-error::after {
    margin-top: 4px;
    max-height: 54px;
  }
}
