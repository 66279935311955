.toolbar {
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.table {
  margin: 0 -24px;
}
.table_container {
  width: 100%;
  overflow-x: auto;
  margin-top: 8px;

  & table {
    overflow: hidden;
  }
}

.table_itself > tbody > tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 17, 51, 0.12);
}

.table_head_row {
  white-space: nowrap;

  & > :first-child {
    min-width: 300px;
  }

  & > :nth-child(3) {
    width: 170px;
  }

  & > :nth-child(4) {
    width: 300px;
  }

  & > :last-child {
    width: 52px;
  }
}

.table_row {
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: #e7ecf0;
  }

  &:active {
    background-color: #dde3e7;
  }
}

.empty {
  background: white !important;

  & td > div {
    padding: 20px;
  }
}

.loading_more {
  background: white !important;

  & td > div {
    padding: 8px;
  }
}

.filters_radio {
  display: flex;
  align-items: center;
  gap: 16px;
}
