.b-bottom-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  width: calc(100% - var(--aside-width));
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  z-index: 1;

  &__submit {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
