.base {
  overflow: hidden;
  position: relative;

  & > div {
    padding-bottom: 24px;
  }
}

.message {
  padding: 12px 16px;
  border-radius: 8px;
  background: #ebecef;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;

  & .header_details {
    display: flex;
    align-items: center;
    gap: 8px;

    & > svg {
      min-width: 24px;
    }

    & .stick {
      width: 1px;
      height: 14px;
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
}

.close {
  min-width: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 16px;
  right: 16px;
}

.content {
  padding-left: 36px;
}

.enter {
  margin-top: -172px;
}

.enterActive {
  transition: all 350ms;
  margin-top: 0;
}

// .exit {
//   margin-top: 0;
// }

// .exitActive {
//   margin-top: -172px;
//   transition: all 350ms;
// }
