.tabs {
  display: flex;

  & > a,
  & > span {
    padding: 12px 16px;
    color: var(--text-secondary);
    text-decoration: none;
    border-top: 1px solid #c2c6ce;
    border-bottom: 1px solid #c2c6ce;
    border-left: 1px solid #c2c6ce;
    height: var(--base-field-height);
    display: flex;
    align-items: center;

    &:first-child {
      border-radius: 6px 0px 0px 6px;
    }

    &:last-child {
      border-radius: 0px 6px 6px 0px;
      border-right: 1px solid #c2c6ce;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.active:not(.disabled) {
      background: #dce8fe;
      color: var(--text-primary);
      border-color: var(--main-primary);

      & + a {
        border-left-color: var(--main-primary);
      }
    }
  }
}
