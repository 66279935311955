.header {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .header_title {
    min-height: var(--base-field-height);
  }

  & .back_link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    gap: 4px;
    padding: 2px 0px;
  }
}

.charts {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.charts > * {
  flex-basis: calc(33.33% - 40px / 3);
  min-width: 364px;
}
