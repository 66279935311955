.search {
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  opacity: 1;
  font-size: 16px;
  line-height: 24px;

  &::after,
  &::before {
    content: none !important;
  }

  & input {
    padding: 0;
    color: var(--text-primary);
  }
}

.searchActive {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.38);
}
