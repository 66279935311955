.from {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.files_list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}
