@import "./fonts.scss";

@mixin sf-bold {
  font-family: "SF Pro Display", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: normal;
}

@mixin sf-semibold {
  font-family: "SF Pro Display", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin sf-text-regular {
  font-family: "SF Pro Text", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin sf-text-medium {
  font-family: "SF Pro Text", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin sf-text-semibold {
  font-family: "SF Pro Text", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin sf-text-bold {
  font-family: "SF Pro Text", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.sf-bold {
  @include sf-bold;
}

.sf-semibold {
  @include sf-semibold;
}

.sf-text-regular {
  @include sf-text-regular;
}

.sf-text-medium {
  @include sf-text-medium;
}

.sf-text-semibold {
  @include sf-text-semibold;
}

.sf-text-bold {
  @include sf-text-bold;
}

.title-h1 {
  @include sf-bold;

  margin: 0;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.36px;
  color: var(--text-primary);
}

.headline-h1 {
  margin: 0;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.36px;
}

.headline-h2 {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.36px;
}

.headline-h3 {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}

.headline-h4 {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.headline-h5 {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
}

.text--primary {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
}

.form-field--text {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.154px;
}

.caption--middle {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.078px;
}

.caption--small {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
}

.table--small {
  margin: 0;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.154px;
}

.table--middle {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.154px;
}

.tags--small {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
}
