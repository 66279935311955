.b-appeals {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -24px -24px;
    position: relative;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__chat {
    --dialogs-width: 436px;
    display: flex;
    height: 100%;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    &_dialogs {
      width: var(--dialogs-width);
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.08);
      padding: 12px 12px 12px 24px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      &_head {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 8px;
      }

      &_list {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &_loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 48px);
      }
    }

    &_messages {
      width: calc(100% - var(--dialogs-width));
      // padding: 12px 24px 12px 12px;
    }
  }
}
