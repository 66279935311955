.field__adornment {
  display: flex;
  align-items: center;
  gap: 8px;

  & svg {
    cursor: pointer;
    user-select: none;
  }
}

.label {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}
