.root {
  border-radius: 20px;
  padding: 16px 44px;
  color: var(--text-primary);

  & .title__wrapper {
    position: relative;

    & .icon {
      position: absolute;
      left: -24px;
      top: 5px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .content {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    white-space: pre-line;
    gap: 4px;
  }
}
