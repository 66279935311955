.b-header {
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  height: 72px;
  box-sizing: border-box;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background-color: var(--main-white);
  left: 0;
  top: 0;

  &__base {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__logo {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

  &__calendar {
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: var(--badge-positive-pale);
    border-radius: 12px;
    height: 44px;
    padding: 8px;

    & > div {
      background-color: var(--background-content);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 28px;
      height: 28px;
      border-radius: 8px;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 8px;
        background-color: var(--badge-positive);
      }

      & > span {
        height: calc(100% - 8px);
        display: flex;
        align-items: center;
      }
    }
  }

  &__giszhkh {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
