.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;

  & > .header_actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.tabs_wrapper {
  padding: 12px 0;
}

.toolbar {
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.table {
  margin: 8px -24px 0;
}

.table_container {
  width: 100%;
  overflow-x: auto;

  & table {
    overflow: hidden;
  }
}

.table_itself > tbody:not(:last-child) {
  border-bottom: 1px solid rgba(0, 17, 51, 0.12);
}

.table_head {
  white-space: nowrap;
}

.table_row {
  & > :first-child {
    width: 146px;
  }
  & > :nth-child(2) {
    width: 120px;
  }
  & > :nth-child(4) {
    width: 204px;
  }
  & > :nth-child(5) {
    width: 72px;
  }
  & > :nth-child(6) {
    width: 238px;
  }
  & > :last-child {
    width: 56px;
  }
}

.filters {
  padding: 6px 0;
}
.filters_options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.empty {
  background: white !important;

  & td > div {
    padding: 20px;

    & .empty_content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 674px;
      }
    }
  }
}

.loading_more {
  background: white !important;

  & td > div {
    padding: 8px;
  }
}

.apartment_row {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: #e7ecf0;
  }
}
