.address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 36px;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: var(--neutral-100);
  }
}
