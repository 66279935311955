.b-select-address-button {
  & > div {
    border-radius: var(--base-field-border-radius);
    border: 1px solid var(--background-border);
    padding: 11px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 10px;
    box-sizing: border-box;
  }

  &::after {
    content: attr(data-error);
    display: block;
    color: var(--text-red);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    max-height: 0;
    margin-top: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  &:not([data-error=""]) {
    & > div {
      border-color: var(--text-red);
    }

    &::after {
      margin-top: 4px;
      max-height: 54px;
    }
  }

  &--disabled {
    pointer-events: none;
  }
}
