.b-appeals-chat {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__stack {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 24px 0 16px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__input {
    width: 100%;
    padding: 24px 24px 24px 12px;
    background-color: var(--background-content);
  }
}
