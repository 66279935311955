$primary: #fff;

$red: #fa4547;

// new design
$secondaryNew: #226dff;
$secondarySelection: #175ce0;

$textPrimary: #2c2c2d;
$textSecondary: #818c99;
$lightText: #6f839e;
$navyHead: #213c61;

$backgroundBorder: rgba(43, 57, 73, 0.16);

$neutral100: #f5f6f8;

$white: #fff;
$black: #000;

// colors kit by figma
$main-neutral: #18212b;
$neutral-200: #e7ecf0;
$main-white: #fff;
$main-primary: #226dff;
$text-secondary: #818c99;
$text-primary: #2c2c2d;
$text-ghost: #a8acb1;
$text-success: #12b212;
$text-olor-text-body-secondary: #76818a;
$error-text: #ed0a34;
$document-type-grey-grey-5: #edeef2;
$document-type-grey-grey-10: #e6e7ed;
$document-type-grey-grey-15: #dcdde8;
$controls-background-ghost-hover: rgba(0, 17, 51, 0.08);
$controls-secondary-background: #eff2f8;
$text-error: #f20000;
$background-border: rgba(43, 57, 73, 0.16);
