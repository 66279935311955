@import "../../../../../assets/styles/colors.scss";

.not_started {
  padding: 4px 0 16px;
  & > h3 {
    color: #ed0a34;
  }
}

.tabs {
  padding: 4px 0 16px;

  & .tabs_wrapper {
    display: flex;
    gap: 16px;
    box-shadow: 0px -1px 0px 0px rgba(0, 17, 51, 0.12) inset;

    & > a {
      text-decoration: none;
      color: $text-secondary;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      position: relative;
      padding: 10px 0;
      height: 40px;

      & > span {
        line-height: 16px;
      }

      &:hover {
        color: $main-neutral;
      }

      &:active {
        color: $main-primary;
      }

      &.active_tab {
        color: $main-primary;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 4px;
          background-color: $main-primary;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}

.content {
  padding: 12px 0;
}
