.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;

  & > .header_actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.tabs_wrapper {
  padding: 12px 0;
}

.rosreestr_message > h4 {
  padding: 2px 0;
}
.rosreestr_message > p {
  margin: 4px 0;
}
