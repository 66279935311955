.instruction_btn {
  cursor: pointer;
}

.statistics {
  background-color: var(--neutral-200);
  border-radius: 20px;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;

  & .statistics_title {
    max-width: 224px;
  }

  & .statistics_content {
    display: flex;
    align-items: center;
    gap: 28px;

    & > div > p {
      margin-top: 4px;
    }
  }
}

.badge_empty {
  padding: 12px 0;
}

.table {
  margin: 8px -24px 0;
}

.filters {
  padding: 6px 0;
}
.filters_options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.empty {
  background: white !important;

  & td > div {
    padding: 20px;

    & > div {
      margin-top: 16px;
      gap: 16px;

      & svg path {
        fill: var(--icons-accent);
      }
    }
  }
}

.loading_more {
  background: white !important;

  & td > div {
    padding: 8px;
  }
}

.instructions_list {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;

  & > li {
    list-style: none;
    display: flex;
    gap: 12px;

    & > span {
      display: block;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 24px;
      background: #e9edf3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > p {
      margin: 2px 0;
    }
  }
}
