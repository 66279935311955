.p-snt-passport-main__header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.b-occupancy-chart {
  display: flex;
  align-items: center;
  justify-content: center;

  &__back {
    stroke: var(--neutral-200);
    stroke-width: 4px;
  }

  &__front {
    transform: rotate(270deg);
    stroke: var(--text-success);
    stroke-width: 4px;
    stroke-dasharray: 126px;
    stroke-dashoffset: 126px;
    transition: stroke-dashoffset 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  & text {
    fill: var(--text-primary);
    font-size: 12px;
    line-height: 16px;
  }
}
