@import "../../assets/styles/colors.scss";

.file {
  display: flex;
  align-items: center;
  gap: 8px;

  & > span {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: calc(100% - 44px);

    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 40px;
      overflow: hidden;
      word-break: break-word;

      & .selectable {
        cursor: pointer;
      }
    }

    & > .actions {
      gap: 12px;

      & span[role="button"] {
        cursor: pointer;
        user-select: none;
      }

      & a {
        text-decoration: none;
      }
    }

    & .file_name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      & > p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      & > svg {
        min-width: 20px;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
